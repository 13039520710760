<template>
  <div class="about-us" oncontextmenu="return false" onselectstart="return false">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" id="pdfDom">
      <div class="table" :class="{'pdfheight': isPrint}">
        <h1 class="title">
          <b>
            【学前儿童发展筛查表—TA-2】
            <br/><br/>个案报告
          </b>
        </h1>
        <div class="author">
          <p>郑玲宜 编制</p>
        </div>
        <p class="title3 tx-l">测验介绍</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>
              <p
                class="tips tx-idt2"
              >
                《学前儿童发展筛查表》（简称TA—2）是用来评估儿童发展水平，筛查出疑似发展迟缓的儿童，并为进一步评估诊断提供参考依据,筛查表面向0-7岁（4-84个月）,每位儿童必须按照要求填写真实信息,筛查表根据儿童的实足年龄评估儿童的发展状况。</p>
            </th>
          </tr>
        </table>
        <div class="head-title">
          <p class="title3 tx-l">基本资料</p>
        </div>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="140px">幼儿姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.child_name"/>
              </div>
            </td>
            <th width="140px">评估日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.create_time"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>受访者姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.parent"/>
              </div>
            </td>
            <th>出生日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.birthday"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>与幼儿关系</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.relation"/>
              </div>
            </td>
            <th>实足年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.age"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>幼儿性别</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.child_sex"/>
              </div>
            </td>
            <th>换算月龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.month_age"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>幼儿排行</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.rank"/>
              </div>
            </td>
            <th>施测者姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.operator"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>幼儿身心发展</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.body"/>
              </div>
            </td>
            <th>受访者电话</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.parent_mobile"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>受访者EMAIL</th>
            <td colspan="3">
              <div class="input">
                <input type="text" readonly v-model="detail.email"/>
              </div>
            </td>
          </tr>
        </table>
        <!-- 家庭资料 -->
        <p class="title3 tx-l">家庭资料</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="140px">母亲实足年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.mother_age"/>
              </div>
            </td>
            <th width="140px">父亲实足年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.father_age"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>母亲出生地</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.mother_town"/>
              </div>
            </td>
            <th>父亲出生地</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.father_town"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>母亲教育程度</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.mother_edu"/>
              </div>
            </td>
            <th>父亲教育程度</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.father_edu"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>家中主要经济来源者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.income"/>
              </div>
            </td>
            <th>家中主要使用语言</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.language"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>主要照顾者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.care_man"/>
              </div>
            </td>
            <th>家庭型态</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="detail.family"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>居住地区</th>
            <td colspan="3">
              <div class="input">
                <input type="text" readonly :value="baseInfo.province + baseInfo.city + baseInfo.district"/>
              </div>
            </td>
          </tr>
        </table>
      
         <p class="title3 tx-l">测验介绍</p>
        <table border="1" cellspacing="1" cellpadding="0">
           <tr>
            <th width="140px">粗大动作</th>
             
            <td class="tx-c" >评估不同阶段儿童坐、站、跑、跳等活动。</td>
            
          </tr>
          <tr>
            <th>精细动作</th>
             
            <td class="tx-c">评估不同阶段儿童抓握、手眼协调、操作工具等。</td>
            
          </tr>
          <tr>
            <th>认知能力</th>
            
                <td class="tx-c">评估不同阶段儿童在注意力、物品概念、基础推理能力。</td>
            
          </tr>
          <tr>
            <th >语言能力</th>
             
            <td class="tx-c">评估不同阶段儿童语音、表达、叙事等方面的能力。</td>
           
          </tr>
          <tr>
            <th>社交能力</th>
             
            <td class="tx-c">评估不同阶段儿童与人互动的能力。</td>
            
          </tr>
        </table>
        </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <!-- 分数结果 -->
        <p class="title3 tx-l">评估结果</p>
        <table border="1" cellspacing="1" cellpadding="0">
       
          <tr>
            <th width="140px">结果分析</th>
            
            <td v-if="rule.length>3">根据分析结果显示:您家宝宝在 
            <p class="tx-1">【{{rule}}】</p> 
            方面可能尚未达到同龄儿童的发展水平。</td>
              <td v-else>根据分析结果显示:您家的宝宝在粗大动作、精细动作、认知能力、语言能力和社交能力五个方面表现达标，符合该年龄段儿童的发展水平!</td>

          </tr>
          <tr>
            <th>结果说明</th>
            <td colspan="13" class="tx-c" v-if="rule.length>3">
              <p class="tips" v-if="detail.month<36||detail.month==36&&detail.weeks_pregnant<37||detail.weeks_pregnant==37">{{sugest}}由於您家寶寶是早產兒，建議納入個案管理系統</p>
              <p class="tips" v-else>{{sugest}}</p>
              
            </td>
            <td colspan="13" class="tx-c" v-else>
              <p class="tips" v-if="detail.month<36||detail.month==36&&detail.weeks_pregnant<37||detail.weeks_pregnant==37">专家提醒：0-7岁是儿童发展的重要阶段，建议定期评估。由於您家寶寶是早產兒，建議納入個案管理系統</p>
               <p class="tips" v-else>专家提醒：0-7岁是儿童发展的重要阶段，建议定期评估。</p>
            </td>
          </tr>
        </table>
         <p class="title3 tx-l">◆	声明</p>
        <p class="title3 tx-l">1、该筛查表由郑玲宜编制。</p>
        <p class="title3 tx-l">2、该筛查表通过与同龄儿童进行比较，判断所评估的儿童发展水平是否达标。</p>
        <p class="title3 tx-l">3、该筛查表仅供家长了解儿童目前发展水平，不作为临床诊断标准。</p>
        <p class="title3 tx-l">4、该筛查表最终解释权归苏州喜安青少儿复能疗育中心所有。</p>
        <p class="title3 tx-l">备注</p>
        <div class="tips">
          <p>1、本筛查表仅供家长掌握孩子目前发展状况，若需进阶信息请进一步评估或与本中心联系。</p>
          <p>2、咨询专线：182-6015-4243  137-8061-5090</p>
          <p>3、如果您对本筛查结果或解释有任何疑问，或者有想进一步了解的地方，请与施测人员讨论与谘询。</p>
          <p>4、其他官网：<a href="http://www.xarw365.com" target="_blank">www.xarw365.com</a></p>
          <p>5、公众号：</p>
          <p style="padding-left: 20px;"><img src="@/assets/img/xian_1.jpg" alt=""></p>
        </div>
      </div>
    </div>
    <button class="print" v-on:click="printPdf">打印</button>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import HeaderNav from "@/components/HeaderNav";
  import FooterNav from "@/components/FooterNav";
  import echarts from "echarts";

  export default {
    name: "aboutus",
    data() {
      return {
        current: "",
        isPrint: false,
        baseInfo: {},
        detail: {},
        cateScore: [],
        sugest:{},
        rule:{},
        /*scoreList: [
          {
            level: 1,
            age: '4个月',
            score: ''
          },
          {
            level: 2,
            age: '6个月',
            score: ''
          },
          {
            level: 3,
            age: '9个月',
            score: ''
          },
          {
            level: 4,
            age: '1岁',
            score: ''
          },
          {
            level: 5,
            age: '1岁3个月',
            score: ''
          },
          {
            level: 6,
            age: '1岁6个月',
            score: ''
          },
          {
            level: 7,
            age: '2岁',
            score: ''
          },
          {
            level: 8,
            age: '2岁6个月',
            score: ''
          },
          {
            level: 9,
            age: '3岁',
            score: ''
          },
          {
            level: 10,
            age: '3岁6个月',
            score: ''
          },
          {
            level: 11,
            age: '4岁',
            score: ''
          },
          {
            level: 12,
            age: '5岁',
            score: ''
          },
          {
            level: 13,
            age: '6岁',
            score: ''
          },
        ],*/
        scoreRe: {},
      };
    },
    components: {
      HeaderNav,
      FooterNav,
      echarts
    },
    methods: {
      getData() {
        let params = {
          exam_id: this.$route.query.eid
        };
        this.$post("report/getReport", params)
          .then(res => {
            if (res.code === 1) {
              this.baseInfo = res.data.base_info;
              this.detail = res.data.base_info.detail;
              this.cateScore = res.data.cate_score;
              //this.scoreRe = res.data.conclusion;
             // let {level,score} = this.scoreRe
              //this.scoreList[level-1].score = score
              this.ruleRe=res.data.rule;
              this.rule=res.data.rule;
              this.sugest=res.data.sugest;
              
              this.score=res.data.score;
              //插入换行符
              this.scoreRe.desc = this.scoreRe.desc.replace(/\n/g, "<br/>");
              this.$nextTick(() => {
                if (this.$route.query.print) {
                  this.printPdf();
                }
              });
            } else {
              this.$layer.msg(res.msg);
            }
          })
          .catch(response => {
            this.$layer.msg("登錄錯誤，请稍后重试！");
          });
      },
      printPdf() {
        this.isPrint = true;
        setTimeout(() => {
          this.getPdf("学前儿童发展筛查表-TA-2", () => {
            this.isPrint = false;
          });
        }, 1500)
      }
    },
    created() {
      this.getData();
    }
  };
</script>

<style lang="less" scoped>
  @import url("../../assets/css/page/result");
</style>
